<template>
  <div>

    <mt-popup
      v-model="careVisible"
      position="center"
      class="Conference"
      :closeOnClickModal="false"
    >
      <img src="@assets/images/Conference.jpg">
    </mt-popup>
  </div>
</template>

<script>
import { getUserInfo } from "@api/user";
export default {
  data() {
    return {
      careVisible: false
    };
  },
  created() {
    this.initEvent();
  },
  methods: {
    initEvent() {
      getUserInfo()
        .then((res) => {
          // subscribe==1 已关注
          if (res.data.subscribe == 1) {
            window.location.href = res.data.img_url;
          } else {
            if(this.ismobile(1) == 0) {
              this.careVisible = true
            } else {
              window.location.href =
                "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU4NTY1MDYxOA==&scene=110&uin=&key=&lang=zh_CN&a8scene=0&fontgear=2";
            }
          }
        })
        .catch((err) => {
          this.$dialog.console.error(err.msg);
        });
    },

    /**
     * [isMobile 判断平台]
     * @param test: 0:iPhone    1:Android
     */
    ismobile() {
      var u = navigator.userAgent;
      if (
        /AppleWebKit.*Mobile/i.test(navigator.userAgent) ||
        /MIDP|SymbianOS|NOKIA|SAMSUNG|LG|NEC|TCL|Alcatel|BIRD|DBTEL|Dopod|PHILIPS|HAIER|LENOVO|MOT-|Nokia|SonyEricsson|SIE-|Amoi|ZTE/.test(
          navigator.userAgent
        )
      ) {
        if (window.location.href.indexOf("?mobile") < 0) {
          try {
            if (/iPhone|mac|iPod|iPad/i.test(navigator.userAgent)) {
              return "0";
            } else {
              return "1";
            }
          } catch (e) {}
        }
      } else if (u.indexOf("iPad") > -1) {
        return "0";
      } else {
        return "1";
      }
    },

  }
};
</script>

<style lang="scss" scoped>
.Conference {
  width: 6.5rem;
  background: initial;
  img {
    width: 100%;
    height: auto;
  }
}
</style>